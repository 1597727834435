<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:90vh">
			<pane min-size="10" size="35">
    			<div style="max-height: 100%" class="overflow-y-auto">
                    <v-data-table :headers="headers" :items="items" item-key="FileId" v-model="selected"
            			:page.sync="page" :items-per-page.sync="itemsPerPage" :server-items-length="totalItems"
                        :loading="loading" loading-text="加载中..." show-select single-select>
                        <template v-slot:top>
                            <v-row class="ma-0">
                                <v-col cols="12" md="2">
                                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="pdate1" label="日期起" readonly v-bind="attrs"
                                                v-on="on" dense hide-details></v-text-field>
                                        </template>
                                        <v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="pdate2" label="日期止" readonly v-bind="attrs"
                                                v-on="on" dense hide-details></v-text-field>
                                        </template>
                                        <v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field v-model="pname" label="姓名" placeholder="客户姓名" clearable dense hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-2">
                                    <v-btn color="primary" @click="fetchData" class="mx-1">查询</v-btn>
                                </v-col>
                            </v-row>
                            <v-divider/>
                        </template>
                        <template v-slot:item.CreateTime="{ item }">
                            {{formatTime(item.CreateTime)}}
                        </template>
                        <template v-slot:item.Duration="{ item }">
                            {{formatSecond(item.Duration)}}
                        </template>
                        <template v-slot:item.Size="{ item }">
                            {{item.Size.toLocaleString()}}
                        </template>
    				</v-data-table>
	            </div>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto">
                    <video-player :options="videoOptions"/>
	            </div>
			</pane>
		</splitpanes>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
    import VideoPlayer from "@/components/VideoPlayer.vue"
	import {formatDate, formatTime, formatSecond} from '../utils'

    export default {
        data() {
            return {
                authed: false,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
                headers: [
                    {text:'订单号', value:'id', width:120},
                    {text:'姓名', value:'username', width:120},
                    {text:'创建时间', value:'CreateTime', width:180},
                    {text:'时长', value:'Duration', width:120},
                    {text:'文件大小', value:'Size', width:120},
                ],
                items: [],
                loading: false,
				selected: [],
				page: 1,
				itemsPerPage: 10,
                totalItems: 0,
                videoOptions: {
                    autoplay: false,
                    controls: true,
                    sources: [{
                        src: "",
                        type: "video/mp4"
                    }]
			    }
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege('视频');
			if (!this.authed) return;
            this.formatTime = formatTime;
            this.formatSecond = formatSecond;
			this.pdate1 = formatDate(Date.now() - 10 * 86400 * 1000);
            this.pdate2 = formatDate(Date.now());
            this.fetchData();
        },
        methods: {
            async fetchData() {
                this.selected = [];
                this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
                        funcname:'getVideoList',
                        data: {
                            username: this.pname,
                            offset: (this.page-1)*this.itemsPerPage,
                            limit: this.itemsPerPage,
                            after: this.pdate1+'T00:00:00+08:00',
                            before: this.pdate2+'T23:59:59+08:00'
                        }
                    }});
                    this.items = res.result ? res.result.MediaInfoSet : [];
                    this.totalItems = res.result ? res.result.TotalCount : 0;
				} catch(err) {
					console.error(err);				
				}
                this.loading = false;
            }
        },
		watch: {
			page() {
				this.fetchData()
			},
			itemsPerPage() {
				this.fetchData()
			},
			selected(newitem, olditem) {
                if (newitem.length === 0) return;
                this.videoOptions.sources[0].src = newitem[0].MediaUrl;
            }
        },
        components: { Splitpanes, Pane, VideoPlayer }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>
