var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('splitpanes',{staticClass:"default-theme",staticStyle:{"height":"90vh"},attrs:{"horizontal":""}},[_c('pane',{attrs:{"min-size":"10","size":"35"}},[_c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"FileId","page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"loading":_vm.loading,"loading-text":"加载中...","show-select":"","single-select":""},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"日期起","readonly":"","dense":"","hide-details":""},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}},'v-text-field',attrs,false),on))]}}],null,false,3135015023),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"日期止","readonly":"","dense":"","hide-details":""},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}},'v-text-field',attrs,false),on))]}}],null,false,1482861369),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"姓名","placeholder":"客户姓名","clearable":"","dense":"","hide-details":""},model:{value:(_vm.pname),callback:function ($$v) {_vm.pname=$$v},expression:"pname"}})],1),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":_vm.fetchData}},[_vm._v("查询")])],1)],1),_c('v-divider')]},proxy:true},{key:"item.CreateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.CreateTime))+" ")]}},{key:"item.Duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSecond(item.Duration))+" ")]}},{key:"item.Size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Size.toLocaleString())+" ")]}}],null,false,1559227114),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]),((_vm.selected.length > 0))?_c('pane',{attrs:{"min-size":"20","size":"65"}},[_c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100%"}},[_c('video-player',{attrs:{"options":_vm.videoOptions}})],1)]):_vm._e()],1)],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }